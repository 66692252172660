import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M5.93425 13.1375C5.61503 13.1375 5.34564 13.0277 5.12607 12.8081C4.90651 12.5886 4.79672 12.3192 4.79672 12C4.79672 11.6808 4.90651 11.4114 5.12607 11.1918C5.34564 10.9723 5.61503 10.8625 5.93425 10.8625H18.0657C18.385 10.8625 18.6544 10.9723 18.8739 11.1918C19.0935 11.4114 19.2033 11.6808 19.2033 12C19.2033 12.3192 19.0935 12.5886 18.8739 12.8081C18.6544 13.0277 18.385 13.1375 18.0657 13.1375H5.93425Z"/>
</svg>`;

const Minus = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='minus' svg={svg} {...props} />
  )
);

Minus.displayName = 'MinusIcon';

export default Minus;
