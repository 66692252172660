/** @jsxImportSource react */
import { actionEventHandler } from '@common/helpers/eventHandlers';
import Minus from '@sly/icons/react/Minus';
import Plus from '@sly/icons/react/Plus';
import { cx } from 'class-variance-authority';
import React, { useRef, useState } from 'react';

interface AccordionProps {
  title: React.ReactNode;
  content: React.ReactNode;
  parseRaw?: boolean;
  blackHeader?: boolean;
  className?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  parseRaw = false,
  blackHeader = false,
  className,
}) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');

  const faqAnswer = content ? content + '' : '';

  const contentSpace = useRef<HTMLDivElement>(null);

  function toggleAccordion() {
    setActive((prevState) => !prevState);
    if (contentSpace.current) {
      setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`);
    }
    actionEventHandler('faq-item', active ? 'close' : 'open', title)();
  }

  const Icon: any = active ? Minus : Plus;

  return (
    <div
      className='flex flex-col'
      data-tp-id='gen-div-49f21334-2ab1-4e06-8b78-159d473bdeaf'
    >
      <div
        className='box-border grid cursor-pointer appearance-none grid-cols-[auto,32px] items-start justify-between gap-xs focus:outline-none'
        onClick={toggleAccordion}
        data-tp-id='gen-div-24691542-5fe1-4c60-8ec0-559e921e7b71'
      >
        <h3
          className={cx(
            'font-t-m inline-block text-left text-viridian-base',
            blackHeader && 'text-slate-base',
            className
          )}
          data-tp-id='gen-h3-9bc92f87-bf10-472c-b5a1-35513a60002f'
        >
          {title}
        </h3>
        <Icon
          color={blackHeader ? 'text-slate-base' : 'text-viridian-base'}
          className='h-6 w-6'
          size='m'
        />
      </div>
      <div
        ref={contentSpace}
        style={{
          maxHeight: `${height}`,
          paddingTop: height !== '0px' ? '1.5rem' : '0',
        }}
        className='transition-max-height no-scrollbar overflow-auto overflow-y-hidden duration-700 ease-in-out'
        data-tp-id='gen-div-1f08237a-2a50-45ad-9653-0304e7c73e2b'
      >
        <div
          className='pb-10 [&_a]:text-viridian-base [&_a:hover]:underline'
          data-tp-id='gen-div-194de07d-f2ac-487a-a2eb-d31527d30efd'
        >
          {!parseRaw ? (
            <>{faqAnswer}</>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: faqAnswer }}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
