import SlyEvent from '@common/helpers/events';

export const clickEventHandler = (category, label) => {
  return () => {
    SlyEvent.getInstance().sendEvent({
      category,
      action: 'click',
      label
    });
  };
};

export const actionEventHandler = (category, action, label) => {
  return () => {
    SlyEvent.getInstance().sendEvent({
      category,
      action,
      label
    });
  };
};
