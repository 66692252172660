/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import Link from '@react/system/Link';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

type BaseMaybeLinkProps = {
  children: React.ReactNode | string;
  palette: string;
  className?: string;
  type?: 'tab' | 'menu';
  active: boolean;
};

type ToMaybeLinkProps = {
  to: string;
  href?: never;
  target?: string;
  onClick?: (args?: string[]) => void;
} & BaseMaybeLinkProps;

type HrefMaybeLinkProps = {
  to?: never;
  href: string;
  target?: string;
  onClick?: (args?: string[]) => void;
} & BaseMaybeLinkProps;

type NoLinkMaybeLinkProps = {
  to?: never;
  href?: never;
  target?: never;
  onClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
} & BaseMaybeLinkProps &
  DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

type MaybeLinkProps =
  | ToMaybeLinkProps
  | HrefMaybeLinkProps
  | NoLinkMaybeLinkProps;

function MaybeLink({ children, ...props }: MaybeLinkProps) {
  if (props.to) {
    const { to, target, ...rest } = props as ToMaybeLinkProps;
    return (
      <Link
        to={to}
        target={target}
        {...rest}
        data-tp-id='gen-Link-3690c1e8-737c-4cf3-8e53-63b0fcebeb24'
      >
        {children}
      </Link>
    );
  }
  if (props.href) {
    const { href, target, ...rest } = props as HrefMaybeLinkProps;
    return (
      <Link
        href={href}
        target={target}
        {...rest}
        data-tp-id='gen-Link-d2bb8ebf-1a00-4cdf-996b-ebbee5a6782c'
      >
        {children}
      </Link>
    );
  }
  const { onClick, ...rest } = props as NoLinkMaybeLinkProps;
  return (
    <span
      {...rest}
      onClick={onClick}
      data-tp-id='gen-span-27ba309f-8c90-4bec-99a8-1da4f5226b34'
    >
      {children}
    </span>
  );
}

type TabProps = {
  type?: 'tab' | 'menu';
  active: boolean;
  children: string | React.ReactNode;
  className?: string;
  id: string;
};

type ToTabProps = {
  to: string;
  href?: never;
  target?: string;
  onClick?: (args?: string[]) => void;
} & TabProps;

type HrefTabProps = {
  to?: never;
  href: string;
  target?: string;
  onClick?: (args?: string[]) => void;
} & TabProps;

type NoLinkTabProps = {
  to?: never;
  href?: never;
  target?: never;
  onClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
} & TabProps;

type UnionTabProps = ToTabProps | HrefTabProps | NoLinkTabProps;

const Tab = ({
  target,
  active,
  children,
  className,
  ...props
}: UnionTabProps) => {
  const palette = active ? 'primary' : 'slate';

  if (props.to) {
    const { to, ...rest } = props as ToTabProps;
    return (
      <MaybeLink
        to={to}
        onClick={rest.onClick}
        className={clsxm(
          active
            ? 'border-b-[0.25rem] border-viridian-base pb-[11px] text-viridian-base'
            : 'pb-[15px] !text-slate-lighter-40',
          'pt-m',
          className
        )}
        palette={palette}
        target={target}
        {...rest}
      >
        {children}
      </MaybeLink>
    );
  } else if (props.href) {
    const { href, ...rest } = props as HrefTabProps;
    return (
      <MaybeLink
        href={href}
        onClick={rest.onClick}
        className={clsxm(
          active
            ? 'border-b-[0.25rem] border-viridian-base pb-[11px] text-viridian-base'
            : 'pb-[15px] !text-slate-lighter-40',
          'pt-m',
          className
        )}
        palette={palette}
        target={target}
        {...rest}
      >
        {children}
      </MaybeLink>
    );
  }

  const { onClick, ...rest } = props as NoLinkTabProps;

  return (
    <MaybeLink
      onClick={onClick}
      palette={palette}
      className={clsxm(
        active
          ? 'font-t-xxs-azo border-b-[0.25rem] border-slate-base pb-[11px] text-slate-base'
          : 'font-b-s pb-[15px] !text-slate-lighter-40',
        'pt-m',
        className
      )}
      {...rest}
    >
      {children}
    </MaybeLink>
  );
};

Tab.defaultProps = {
  type: 'tab',
  active: false,
};

export default Tab;
