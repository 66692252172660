/** @jsxImportSource react */
import clsxm from '@common/lib/clsxm';
import { bool, instanceOf, string } from 'prop-types';
import React, { Component } from 'react';

const getDefaultActiveTab = (children) => {
  for (const element of children) {
    if (element.props.default) {
      return element.props.id;
    }
  }
  return children[0].props.id;
};

export default class Tabs extends Component {
  static propTypes = {
    children: instanceOf(Array).isRequired,
    activeTab: string,
    className: string,
    noBorder: bool,
  };

  static getDerivedStateFromProps(props, state) {
    const activeTab = props.activeTab || state.activeTab;
    return {
      ...state,
      activeTab,
    };
  }

  constructor(props) {
    super(props);

    const { children } = this.props;

    let { activeTab } = this.props;

    if (!activeTab) {
      activeTab = getDefaultActiveTab(children);
    }

    this.state = {
      activeTab,
    };
  }

  onClickTabItem = (id, callback) => this.setState({ activeTab: id }, callback);

  render() {
    const { children, className } = this.props;
    const { activeTab } = this.state;

    return (
      <div
        className='no-scrollbar sticky top-0 z-stickyNav w-full overflow-x-auto border-b border-slate-lighter-90 bg-white p-0 text-xs font-bold md:rounded-none'
        data-tp-id='gen-div-907b4fe9-962b-45f9-be9d-006cedb2a688'
      >
        <div
          className={clsxm(
            'flex overflow-y-hidden whitespace-nowrap bg-white uppercase',
            'gap-x-l lg:mx-auto lg:px-0',
            className
          )}
          data-tp-id='gen-div-7e70ef6a-f6b8-4482-a2dd-de022056b1b5'
        >
          {children.map((child) => {
            if (!child) return null;
            const { id, onClick } = child.props;
            return React.cloneElement(child, {
              key: id,
              active: activeTab === id,
              onClick: child.props.to
                ? onClick
                : (e) => this.onClickTabItem(id, () => onClick(e)),
            });
          })}
        </div>
      </div>
    );
  }
}
