import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M10.8625 13.1375H5.93425C5.61503 13.1375 5.34564 13.0277 5.12607 12.8081C4.90651 12.5886 4.79672 12.3192 4.79672 12C4.79672 11.6808 4.90651 11.4114 5.12607 11.1918C5.34564 10.9723 5.61503 10.8625 5.93425 10.8625H10.8625V5.93425C10.8625 5.61503 10.9723 5.34564 11.1918 5.12607C11.4114 4.90651 11.6808 4.79672 12 4.79672C12.3192 4.79672 12.5886 4.90651 12.8081 5.12607C13.0277 5.34564 13.1375 5.61503 13.1375 5.93425V10.8625H18.0657C18.385 10.8625 18.6544 10.9723 18.8739 11.1918C19.0935 11.4114 19.2033 11.6808 19.2033 12C19.2033 12.3192 19.0935 12.5886 18.8739 12.8081C18.6544 13.0277 18.385 13.1375 18.0657 13.1375H13.1375V18.0657C13.1375 18.385 13.0277 18.6544 12.8081 18.8739C12.5886 19.0935 12.3192 19.2033 12 19.2033C11.6808 19.2033 11.4114 19.0935 11.1918 18.8739C10.9723 18.6544 10.8625 18.385 10.8625 18.0657V13.1375Z"/>
</svg>`;

const Plus = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='plus' svg={svg} {...props} />
  )
);

Plus.displayName = 'PlusIcon';

export default Plus;
